import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ResultList = styled.div`
	margin: 4px 12px;
	h5 {
		font-weight: normal;
		margin-bottom: 5px;

		&.match {
			font-weight: 500;
			line-height: 1.2;
			margin-bottom: 5px;
		}
	}

	ul {
		list-style: none;
		padding: 4px 0;
		margin: 0;
		user-select: none;

		li {
			padding: 8px;
			transition: background 0.3s;
			&:first-of-type {
				padding-top: 4px;
			}

			.algolia-docsearch-suggestion--highlight {
				font-weight: 500;
			}

			p {
				line-height: 24px;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				color: #285056;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				margin: 0;
			}

			&:hover {
				background: #f6fcff;
				border-radius: 6px;
				cursor: pointer;
				color: #285056;
			}
		}
	}
`;

const getheader = (hierarchy) => {
	if(hierarchy){
		const result = Object.keys(hierarchy).reduce(function(res, v) {
			return res.concat(hierarchy[v].value !== null ? hierarchy[v].value : '');
		}, []);
		
		return result.filter(val => val).join(' > ')
	}
}

const getLink = (tag, key, clearSearch) => {
	let link = "";

	if(tag === 'api') {
		link = key.path.includes((`/${tag}`)) ? <Link to={key.path} onClick={clearSearch}>
																	<h5 dangerouslySetInnerHTML={{ __html: getheader(key.hierarchy) }} />
																	<p dangerouslySetInnerHTML={{ __html: key.content }} />
																	</Link>
																:
																	<a href={`/docs${key.path}`} onClick={clearSearch} target="_blank" rel="noopener noreferrer">
																		<h5 dangerouslySetInnerHTML={{ __html: getheader(key.hierarchy) }} />
																		<p dangerouslySetInnerHTML={{ __html: key.content }} />
																	</a>
	} else {
		link = !key.path.includes('/api') ? <Link to={key.path} onClick={clearSearch}>
																				<h5 dangerouslySetInnerHTML={{ __html: getheader(key.hierarchy) }} />
																				<p dangerouslySetInnerHTML={{ __html: key.content }} />
																			</Link>
																		:
																			<a href={`/docs${key.path}`} onClick={clearSearch} target="_blank" rel="noopener noreferrer">
																				<h5 dangerouslySetInnerHTML={{ __html: getheader(key.hierarchy) }} />
																				<p dangerouslySetInnerHTML={{ __html: key.content }} />
																			</a>
	}

	return link;
}

const Result = ({ resultSet, clearSearch, tag }) => {
	return (
		<ResultList>
			<ul>
				{resultSet.map((key,index) => (
					<li key={index}>
						{ getLink(tag, key, clearSearch) }
					</li>
				))}
			</ul>
		</ResultList>
	);
};

export default Result;
