import React from 'react';
import styled from 'styled-components';

import Home from '../../assets/images/svg/home.svg';
import Payments from '../../assets/images/svg/payments.svg';
import Transfers from '../../assets/images/svg/transfers.svg';
import Identity from '../../assets/images/svg/identity-verification.svg';
import Libraries from '../../assets/images/svg/libraries-plugins.svg';
import Guides from '../../assets/images/svg/guides.svg';
import API from '../../assets/images/svg/api-reference.svg';

const RoundPill = styled.div`
	display: flex;
	align-items: center;
	background: rgba(242, 245, 247, 0.5);
	border-radius: 0px 20px 20px 0px;
	width: fit-content;
	padding: 2px 14px 2px 24px;
	opacity: 0.8;
	border-left: none;

	h5 {
		font-weight: 500;
		font-size: 12px;
		text-transform: uppercase;
		color: #285056;
		line-height: 1;
		letter-spacing: 0.2px;
	}

	.pill-icon-wrap {
		max-width: 18px;
		margin-right: 5px;
		display: flex;
		svg {
			width: 100%;
			height: 16px;
			vertical-align: middle;
			* {
				fill: #728f9e;
			}
		}
	}
`;

const Pill = ({ category, hasIcon }) => {
	const pillIcons = {
		home: <Home />,
		payments: <Payments />,
		transfers: <Transfers />,
		identity: <Identity />,
		libraries: <Libraries />,
		guides: <Guides />,
		api: <API  />
	};

	return (
		<RoundPill>
			{ hasIcon === 'true' && (
				<span className="pill-icon-wrap">
					{pillIcons[category.split('-')[0]]}
				</span>
			) }
			<h5>{category}</h5>
		</RoundPill>
	);
};

export default Pill;
