import React from 'react';

import Pill from '../SearchResult/Pill';
import Result from '../SearchResult/Result';

const parseHits = (hits) => {
	let parsedHits = {}

	if(hits && Object.keys(hits).length > 0) {
		parsedHits = hits.map(attr => {
			let url = new URL(attr.url)
			let path = url.pathname.replace("/docs", "")
			let hash = url.hash === '#gatsby-focus-wrapper' ? '' : url.hash;
			let category = path.substring(1).split("/")[0].length > 0 ? path.substring(1).split("/")[0] : "home"
			return {
				category,
				path: path + hash,
				hierarchy: attr._highlightResult.hierarchy,
				content: attr._highlightResult.content ? attr._highlightResult.content.value : ""
			}
		})

		return parsedHits.reduce(function (result, accumulator) {
			result[accumulator.category] = result[accumulator.category] || [];
			result[accumulator.category].push(accumulator);
			return result;
		}, Object.create(null));
	}
	
}

const SearchResult = ({hits, clearSearch, hasIcon, tag}) => {
	return (
		<>
			{Object.entries(parseHits(hits)).map((key, index) => (
				<div key={index}>
					<Pill category={key[0]} hasIcon={hasIcon} />
					<Result resultSet={key[1]} clearSearch={clearSearch} tag={ tag } />
				</div>
			)) }
		</>
	);
};

export default SearchResult;
